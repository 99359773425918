<template>
  <div class="form-check form-switch">
    <input
      @input="setValue($event)"
      :checked="rawValue"
      class="form-check-input"
      type="checkbox"
      id="editor-set-propagation"
    >
    <label class="form-check-label" for="editor-set-propagation">
      Auto-propagate reps and weight numbers
    </label>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'SetPropagationConfig',
  computed: {
    ...mapState('config', ['configUISetPropagation']),
    rawValue() {
      const { configUISetPropagation } = this;
      if (typeof configUISetPropagation === 'boolean') {
        return configUISetPropagation;
      }
      return configUISetPropagation === 'true';
    },
  },
  methods: {
    setValue(e) {
      const value = e.target.checked;
      this.$store.dispatch('config/setUISetPropagation', {
        vm: this,
        value,
      });
    },
  },
};
</script>
